// let showHint = (str, event = null) => {
//     document.getElementsByClassName('category active')[0]?.classList.remove('active');
    
//     if(event) {
//         event.srcElement.classList.add('active')
//     }

//     fetch("gallery?cat_name="+str).then(function (response) {
//         return response.text();
//     }).then(function (html) {
//         if (!html.length) { 
//             document.getElementById("gallary-wrap").innerHTML = "";
//             return;
//         }
//         document.getElementById("gallary-wrap").innerHTML = html;
//     }).catch(function (err) {
//         document.getElementById("gallary-wrap").innerHTML = "";
//     });
// }
// showHint('all');


// const testimonials = [
//     {
//       hotelImage: "{{asset('/images/m6_hotel.png')}}",
//       reviewerImage: "{{asset('/images/anthony.png')}}",
//       text: "Grizlu's sass app revolutionized our hotel. Streamlined operations, reduced errors, and boosted efficiency.",
//       name: "Anthony Bahringer",
//       position: "Motel6 Regional Manager"
//     },
//     {
//       hotelImage: "{{asset('/images/hotel2.png')}}",
//       reviewerImage: "{{asset('/images/john.png')}}",
//       text: "A fantastic app! Made managing bookings so much easier with real-time data insights.",
//       name: "John Doe",
//       position: "Manager at Hotel Bliss"
//     },
//     {
//       hotelImage: "{{asset('/images/hotel3.png')}}",
//       reviewerImage: "{{asset('/images/jane.png')}}",
//       text: "Excellent service and top-notch app features. Highly recommended!",
//       name: "Jane Smith",
//       position: "CEO at Luxury Inn"
//     }
//   ];

//   let currentIndex = 0;

//   // Elements to update
//   const hotelImg = document.getElementById("hotel-img");
//   const reviewerImg = document.getElementById("reviewer-img");
//   const reviewText = document.getElementById("review-text");
//   const reviewerName = document.getElementById("reviewer-name");
//   const reviewerPosition = document.getElementById("reviewer-position");

//   // Update testimonial content
//   function updateTestimonial(index) {
//     const testimonial = testimonials[index];
//     hotelImg.src = testimonial.hotelImage;
//     reviewerImg.src = testimonial.reviewerImage;
//     reviewText.textContent = testimonial.text;
//     reviewerName.textContent = testimonial.name;
//     reviewerPosition.textContent = testimonial.position;
//   }

//   // Event listeners for buttons
//   document.getElementById("prev-btn").addEventListener("click", (event) => {
//     event.preventDefault();
//     currentIndex = (currentIndex - 1 + testimonials.length) % testimonials.length;
//     updateTestimonial(currentIndex);
//   });

//   document.getElementById("next-btn").addEventListener("click", (event) => {
//     event.preventDefault();
//     currentIndex = (currentIndex + 1) % testimonials.length;
//     updateTestimonial(currentIndex);
//   });